import React from "react";

import classNames from "classnames/bind";
import styles from "./section.module.scss";
let cx = classNames.bind(styles);

const Section = ({ sectionClassName, sectionId, children }) => {
  const sectionStyles = cx({
    section: true,
    [sectionClassName]: sectionClassName,
  });

  return (
    <section id={sectionId} className={sectionStyles}>
      {children}
    </section>
  );
};

const Hero = ({ innerClassName, sectionClassName, sectionId, children }) => {
  const heroStyles = cx({
    hero: true,
    [sectionClassName]: sectionClassName,
  });

  const innerStyles = cx({
    inner: true,
    [innerClassName]: innerClassName,
  });

  return (
    <Section sectionId={sectionId} sectionClassName={heroStyles}>
      <div className={innerStyles}>{children}</div>
    </Section>
  );
};

const SectionBuilder = ({
  hero,
  innerClassName,
  sectionClassName,
  sectionId,
  children,
}) => {
  if (hero) {
    return (
      <Hero
        innerClassName={innerClassName}
        sectionClassName={sectionClassName}
        sectionId={sectionId}
      >
        {children}
      </Hero>
    );
  } else {
    return (
      <Section sectionClassName={sectionClassName} sectionId={sectionId}>
        {children}
      </Section>
    );
  }
};

export default SectionBuilder;
