import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

import styles from "./carer-inline-preview.module.scss";
import cx from "classnames";

const CarerInlinePreview = ({ size, animate, reverse, carer }) => {
  let options = {
    [styles.medium]: size == undefined || size == "m",
    [styles.large]: size == "l",
    [styles.small]: size == "s",
    [styles.reverse]: reverse == true,
    [styles.animate]: animate == true,
  };

  let inlineStyles = cx(styles.container, options);

  return (
    <Link to={`/carer/${carer.slug}`} className={styles.link}>
      <div className={inlineStyles}>
        <div className={styles.avatar}>
          <Img className={styles.image} alt="" fluid={carer.carerImage.fluid} />
        </div>
        <span className={styles.text}>{carer.fullName}</span>
      </div>
    </Link>
  );
};

export default CarerInlinePreview;
