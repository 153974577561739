import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import metaImage from "../images/seo_meta_image.png";

import touchicon from "../images/apple-touch-icon.png";
import favicon16 from "../images/favicon-16x16.png";
import favicon32 from "../images/favicon-32x32.png";

const SEO = ({ description, title, image }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  console.log("header image", image, metaImage);
  let imageUrl = metaImage;
  let twitterCard = `summary_large_image`;

  let OGtitle = site.siteMetadata.title;
  if (title != undefined) {
    OGtitle = `${title} • ${site.siteMetadata.title}`;
  }

  // if (image != undefined) {
  //   twitterCard = `summary`;
  //   imageUrl = image;
  // }

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={title}
      titleTemplate={`%s · ${site.siteMetadata.title}`}
      defaultTitle={site.siteMetadata.title}
      link={[
        {
          rel: "apple-touch-icon",
          type: "image/png",
          sizes: "180x180",
          href: `${touchicon}`,
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: `${favicon16}`,
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: `${favicon32}`,
        },
        { rel: "manifest", href: `/site.webmanifest` },
      ]}
      meta={[
        {
          name: "description",
          content: description || site.siteMetadata.description,
        },
        {
          property: `og:title`,
          content: title || site.siteMetadata.title,
        },
        {
          property: "og:description",
          content: description || site.siteMetadata.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        {
          property: `twitter:card`,
          content: twitterCard,
        },
        {
          property: `twitter:image`,
          content: imageUrl,
        },
      ]}
    />
  );
};

export default SEO;
