import React from "react";
import { Link, graphql } from "gatsby";
import get from "lodash/get";
import SEO from "../components/seo";

import Layout from "../components/layout";
import Section from "../components/section";
import CarerInlinePreview from "../system/carer-inline-preview";

import pageStyles from "./page.module.scss";
import styles from "./services.module.scss";

class Services extends React.Component {
  render() {
    const siteTitle = "Services";
    const services = get(this, "props.data.allContentfulServices.edges");
    return (
      <Layout location={this.props.location}>
        <SEO title={siteTitle} />{" "}
        <Section>
          <div className={pageStyles.headline}>
            <h2>Services currently offered</h2>{" "}
            <p>
              All our carers offer a personalised service to suit your needs so
              don't hesitate to <Link to="/contact">contact us</Link> if you
              have requirements that aren't covered here.
            </p>
          </div>
          <ul className={styles.services}>
            {services.map(({ node }, index) => {
              if (!node.caregiver) return;

              return (
                <li key={`services_${index}`} className={styles.service}>
                  <p>Offering help with</p>
                  <h4>{node.title}</h4>
                  {/* <p>from:</p> */}
                  <div className={styles.offered}>
                    <ul className={styles.carers}>
                      {node.caregiver.map((caregiver) => {
                        if (caregiver.status != "active") return;
                        return (
                          <li key={`${node.title}_${caregiver.slug}`}>
                            <CarerInlinePreview size="s" carer={caregiver} />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
              );
            })}
          </ul>
        </Section>
      </Layout>
    );
  }
}

export default Services;

export const pageQuery = graphql`
  query ServicesQuery {
    allContentfulServices(sort: { fields: caregiver, order: DESC }) {
      edges {
        node {
          specialist
          title
          caregiver {
            fullName
            status
            slug
            carerImage {
              fluid(
                cropFocus: FACE
                resizingBehavior: FILL
                quality: 100
                maxWidth: 80
                maxHeight: 80
              ) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  }
`;
